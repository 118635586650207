import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";
import StaticPageContent from "components/StaticPageContent";

const CookiePolicy = ({ data }) => {
  const content = data?.privacyPolicy?.content;
  const seo = data?.privacyPolicy?.seo;

  return (
    <Layout
      seo={{
        title: seo?.title,
        description: seo?.metaDesc || "",
      }}
    >
      <StaticPageHeader
        title="Polityka Plików Cookies"
        text="Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony rodzinneresorty.pl. Przez używanie serwisu rodzinneresorty.pl wyrażasz zgodę na używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli nie zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z używania strony rodzinneresorty.pl."
      />
      <StaticPageContent content={content} />
    </Layout>
  );
};

export default CookiePolicy;

export const query = graphql`
  query {
    privacyPolicy: wpPage(id: { eq: "cG9zdDoxOQ==" }) {
      content
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
